import React from "react";
import Button from "../Button";

import "../../styles/hero.scss";
import heroImage from "../../img/doctor_home.svg";
import heroLaserImage from "../../img/laser_hero.svg";
import heroSurgeryImage from "../../img/surgery_hero.png";

const Hero = ({
  className = "",
  h1 = "",
  oneLiner = "",
  oneLinerMore = "",
  ctaPrimary = null,
  ctaSecondary = null,
  type = "doctor",
}) => {
  const heroImageSelected = { doctor: heroImage, laser: heroLaserImage, surgery: heroSurgeryImage };

  return (
    <div className={`hero ${className}`}>
      <div className="hero-inner">
        <div className="one-liner-wrapper">
          <h1>{h1}</h1>
          <div className="one-liner-text">{oneLiner}</div>
          {oneLinerMore && <div className="one-liner-text">{oneLinerMore}</div>}
          {oneLinerMore ? (
            <div className="one-liner-text-mobile">
              {oneLiner} {oneLinerMore}
            </div>
          ) : (
            <div className="one-liner-text-mobile">{oneLiner}</div>
          )}
          <div className="buttons">
            <Button>{ctaPrimary}</Button>
            {ctaSecondary && (
              <Button type="secondary" size="L">
                {ctaSecondary}
              </Button>
            )}
          </div>
        </div>

        <div className="image-wrapper">
          <img src={heroImageSelected[type]} alt={h1} title={h1} />
        </div>
      </div>
    </div>
  );
};

export default Hero;
